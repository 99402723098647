body, html {
    background-color: #007F7F;
}

.page {
    padding:0;
    margin-top: 14px;
    margin-left: auto;
    margin-right: auto;
    width:640px;
}


.page .sidebar button {
    margin:0.1em;
    width:100%;
}

.mapCanvas{
    position:relative;
    background-color: black;
    border-radius: 10px;
}

.mapCanvasBg{
    position:relative;
    top:0;
    left:0;
    background-color: black;
    transition: opacity 1s;
    width:100%;
}

.mapCanvasBg:hover {
    opacity: 0.5;
}

.playerOnMap {
    position:absolute;
    height:10px;
    width:10px;
    color: rgba(255, 255, 255, 0.0);
    background-color: #ffde73;
    border: 1px black solid;
    border-radius: 3px;
}

.playerOnMap:hover {
    position:absolute;
    height:auto;
    width:auto;
    color: rgba(0, 0, 0, 1.0);
    z-index:99;
}

.manualContainer{

    text-align: justify;
    text-justify: inter-word;
}

.manualContainer img {
  max-width: 500px;
}
.manualContainer > img {
  max-width: 500px;
  height: auto;
}


.window {
    width:640px;
    max-width: 640px;
}

@media screen and (max-width: 640px) {
  .page {
    width:100%;
    margin-left: 0;
    margin-right: 0;
  }
  .window {
    width:100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .windowContent {
    transform: scale(0.5);
  }
  .manualContainer img {
    max-width: 100%;
    height: auto;
  }
  .manualContainer > img {
    max-width: 100%;
    height: auto;
  }
}
.link{
    color: rgb(16, 52, 166);
    text-decoration: underline;
}


.resp-container {
  position: relative;
  overflow: hidden;
  min-height:600px;
}
.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
